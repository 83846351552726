var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container-lg px-4 content py-5"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-outline-primary mb-3",attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 返回列表 ")])]),_c('div',{staticClass:"col-12 title fs-1 fw-bold"},[_vm._v("商务经理")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jumbotron-wrapper image"},[_c('div',{staticClass:"jumbotron container-lg px-4"},[_c('div',{staticClass:"animation cover-text mt-5 pt-5 ps-0"},[_c('div',[_vm._v("和我们，做有挑战的事！")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"badge border text-primary border-primary"},[_vm._v("市场")]),_c('div',{staticClass:"ms-2 badge border text-primary border-primary"},[_vm._v(" 社会招聘 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位描述")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 服务机构客户，以私募证券类、券商机构为主，针对所负责区域内的金融机构客户进行开发与维护，包括日常客户走访，收集客户需求，向客户提供市场咨询，与客户保持良好关系； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 根据公司金融机构业务规划，统筹金融机构整体营销方案的具体落地执行，推动金融机构开发及合作，促成多赢的谈判结果； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 根据客户需求，提供算法交易一体化解决方案。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"requirement mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位要求")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 学历专业不做要求，对计算机、金融科技、算法交易有深刻认知和理解加分； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 对行业经验不做要求，曾在券商、交易平台、恒生、迅投、私募、FOF机构等任职优先考虑； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 需要具有学习能力和良好的沟通能力，语言表达能力、逻辑思维能力、探索精神、主观能动性； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 具有较强的活动组织能力和抗压能力，良好的团队合作精神和协作能力均为加分项； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 拥有有券商资源，面貌形象好为加分项。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("工作城市")]),_c('div',{staticClass:"mt-2"},[_c('span',[_vm._v(" 上海 ")]),_c('span',[_vm._v(" 北京 ")]),_c('span',[_vm._v(" 深圳 ")]),_c('span',[_vm._v(" 成都 ")]),_c('span',[_vm._v(" 徐州 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("联系方式")]),_c('div',{staticClass:"mb-2"},[_vm._v("简历请发送至邮箱：recruit@ft.tech")]),_c('img',{attrs:{"src":require("../../assets/img/招聘求职.jpg"),"align":"center"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-primary px-5",attrs:{"href":"mailto:recruit@ft.tech"}},[_vm._v(" 简历投递 ")])])
}]

export { render, staticRenderFns }